import React, { useRef, useState } from 'react';
import {
    ListItemButton, ListItemText, IconButton, InputBase, Menu, MenuItem, ListItemIcon, Box, Typography,
    useTheme
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useSaveConversationName from '../hook/useSaveConversationName';
import useDeleteConversation from '../hook/useDeleteConversation';
import CustomDialog from './CustomDialog';
import { useColorMode, colors } from '../context/ColorModeContext';


interface Conversation {
    id: string;
    name: string;
    ticket_flag: boolean;
    updated_at: string;
}

interface ConversationItemProps {
    conversation: Conversation;
    isSelected: boolean;
    onSelect: (id: string, isCreatedTicket: boolean) => void;
    handleCreateConversation: () => void;
}

const ConversationItem: React.FC<ConversationItemProps> = ({
    conversation,
    isSelected,
    onSelect,
    handleCreateConversation,
}) => {
    const theme = useTheme()

    const { mode } = useColorMode()
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editedName, setEditedName] = useState<string>(conversation.name);
    const inputRef = useRef<HTMLInputElement>(null);

    const { mutate: saveName } = useSaveConversationName();

    const { mutate: deleteConversation } = useDeleteConversation()

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleEditClick = (event: React.MouseEvent<HTMLElement>) => {
        setIsEditing(true);
        handleMenuClose(event);
        setTimeout(() => inputRef.current?.focus(), 100);
    };

    const handleSave = () => {
        if (editedName.trim()) {
            saveName({ conversation_id: conversation.id, name: editedName.trim() });
        }
        setIsEditing(false);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedName(event.target.value);
    };

    const handleInputBlur = () => {
        handleSave();
    };

    const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
        handleOpen()
        handleMenuClose(event);

    };

    const handleListItemClick = () => {
        if (!isEditing) {
            onSelect(conversation.id, conversation.ticket_flag);
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(false)
    };
    const handlePrimaryAction = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        deleteConversation({ conversation_id: conversation.id });
        if (isSelected) {
            setTimeout(() => {
                handleCreateConversation()
            }, 100)
        }
        handleClose(event);
    };
    const handleSecondaryAction = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        handleClose(event);
    };

    return (
        <ListItemButton
            selected={isSelected}
            onClick={handleListItemClick}
            sx={{ position: 'relative', '&:hover .three-dot-button': { visibility: 'visible' } }}
        >
            {isEditing ? (
                <InputBase
                    inputRef={inputRef}
                    value={editedName}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    fullWidth
                    sx={{
                        fontSize: '14px',
                        padding: '0 8px',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '4px',
                        fontWeight: 'bold',
                    }}
                />
            ) : (
                <ListItemText
                    primary={
                        <Box sx={{
                            width: '250px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>

                            <Typography
                                variant="h6"
                                component="span"
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {conversation.name}
                            </Typography>
                        </Box>

                    }
                    secondary={
                        <>
                            <Typography
                                component="span"
                                variant="caption"
                                color="textSecondary"
                            >
                                {conversation.updated_at}
                            </Typography>
                        </>
                    }
                />
            )}
            <IconButton
                className="three-dot-button"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                sx={{
                    visibility: isSelected ? 'visible' : 'hidden',
                    position: 'absolute',
                    right: 0,
                }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEditClick}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Change name</Typography>
                </MenuItem>
                <MenuItem onClick={handleDeleteClick}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" sx={{ color: 'red' }} />
                    </ListItemIcon>
                    <Typography variant="inherit" sx={{ color: '#cc0000'}}>Delete</Typography>
                </MenuItem>
            </Menu>
            <CustomDialog
                open={open}
                onClose={handleClose}
                title="Delete chat?"
                content={<div style={{ display: 'flex', flexDirection: 'row' }}><Typography variant='h4'>This action will remove the</Typography><Typography sx={{ ml: 0.5, fontWeight: 600}} variant='h4'>{`${conversation.name}`}</Typography><Typography variant='h4'>.</Typography></div>}
                onPrimaryAction={handlePrimaryAction}
                onSecondaryAction={handleSecondaryAction}
                primaryActionText="Delete"
                secondaryActionText="Cancel"
                primaryTextColor={'white'}
                secondaryTextColor={'black'}
                primaryButtonColor={'#ff0000'}
                primaryButtonHoverColor={mode === 'dark' ? '#cc0000' : '#cc0000'}
                secondaryButtonColor={mode === 'dark' ? colors.grey[200] : colors.grey[100]}
                secondaryButtonHoverColor={mode === 'dark' ? colors.grey[300] : colors.grey[200]}
            />
        </ListItemButton>
    );
};

export default ConversationItem;
