import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../utils/http';
import { useConversations } from './useConversations';

const useDeleteConversation = () => {
    const { deleteConversation } = useConversations()
    return useMutation({
        mutationFn: deleteConversation,
        onMutate: (deletedConversation) => {
            queryClient.setQueryData(['conversations'], (oldData: any) => {
                if (!oldData) return oldData;

                const updatedPages = oldData.pages.map((page: any) => (page.filter((conv: any) =>
                    conv.id !== deletedConversation.conversation_id
                )
                ))

                return {
                    ...oldData,
                    pages: updatedPages,
                };
            })
        }
    })

}

export default useDeleteConversation;