import React, { useRef, useEffect, useState, KeyboardEvent } from 'react';
import { IconButton, InputBase, Box } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Close from '@mui/icons-material/Close';
import { useColorMode, colors } from '../context/ColorModeContext';
import axios from 'axios';
import RecordingButton from './RecordingButton';
// Declare Web Speech API types
declare global {
    interface Window {
        webkitSpeechRecognition: any;
        SpeechRecognition: any;
    }
}

interface InputBarProps {
    onSendMessage: (message: string) => void;
    disabled?: boolean;
    notAllowContinueConversation?: boolean;
    conversationId?: null | string;
    inputRef: any;
}

const convertAudioBlobToBase64 = (audioBlob: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const arrayBuffer: any = reader.result;
            const base64Audio = btoa(
                new Uint8Array(arrayBuffer).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            );
            resolve(base64Audio);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(audioBlob);
    });
};

const InputBar: React.FC<InputBarProps> = ({ inputRef, notAllowContinueConversation, conversationId, onSendMessage, disabled = false }) => {
    const { mode } = useColorMode();
    const isDarkMode = mode === "dark";

    const [message, setMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [conversationId]);

    const [recording, setRecording] = useState(false);

    const recognitionRef = useRef<any>(null);
    const mediaRecorderRef = useRef<any>(null);
    const audioChunksRef = useRef<Blob[]>([]);

    const isWebSpeechSupported = () => {
        return !!(window.SpeechRecognition || window.webkitSpeechRecognition);
    };

    const initializeSpeechRecognition = () => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) return null;

        const recognition = new SpeechRecognition();
        recognition.interimResults = true;
        recognition.continuous = true;

        recognition.onresult = (event: any) => {
            let interimTranscript = '';
            let finalTranscript = '';

            for (let i = event.resultIndex; i < event.results.length; i++) {
                const transcript = event.results[i][0].transcript;
                if (event.results[i].isFinal) {
                    finalTranscript += transcript + ' ';
                } else {
                    interimTranscript += transcript + ' ';
                }
            }

            setMessage(finalTranscript || interimTranscript);
        };

        recognition.onend = () => {
            setRecording(false);
        };

        recognition.onerror = (event: any) => {
            console.error('Speech recognition error:', event.error);
            setRecording(false);
        };

        recognitionRef.current = recognition;
    };

    // if (!process.env.REACT_APP_GOOGLE_API_KEY) {
    //     throw new Error("REACT_APP_GOOGLE_API_KEY not found in the environment");
    // }

    const apiKey = 'AIzaSyAaELpkSfqmYifJEm0gkDv_onVBksrEYI8';

    const handleGoogleSpeechToText = async (audioBlob: Blob) => {
        try {
            const base64Audio = await convertAudioBlobToBase64(audioBlob);
            const response = await axios.post(`https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`, {
                audio: {
                    content: base64Audio,
                },
                config: {
                    languageCode: 'en-US',
                    enableAutomaticPunctuation: true,
                },
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const transcript = response.data.results
                .map((result: any) => result.alternatives[0].transcript)
                .join('\n');

            setMessage(transcript);
        } catch (error) {
            console.error('Google Speech-to-Text API Error:', error);
        }
    };

    const startRecording = () => {
        if (isWebSpeechSupported()) {
            if (recognitionRef.current) {
                recognitionRef.current.start();
                setRecording(true);
            }
        } else {
            // Fallback to using MediaRecorder for Google Speech-to-Text
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    const options = { mimeType: 'audio/webm' };
                    const mediaRecorder = new MediaRecorder(stream, options);
                    mediaRecorderRef.current = mediaRecorder;
                    audioChunksRef.current = [];

                    mediaRecorder.ondataavailable = (event) => {
                        audioChunksRef.current.push(event.data);
                    };

                    mediaRecorder.onstop = async () => {
                        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                        await handleGoogleSpeechToText(audioBlob);  // Corrected function call
                    };

                    mediaRecorder.start();
                    setRecording(true);
                })
                .catch((error) => {
                    console.error('Error accessing microphone:', error);
                });
        }
    };

    const stopRecording = () => {
        if (isWebSpeechSupported() && recognitionRef.current) {
            recognitionRef.current.stop();
            setRecording(false);
        } else if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setRecording(false);
        }
    };

    useEffect(() => {
        initializeSpeechRecognition();
    }, []);

    const handleSendMessage = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (message.trim() && !isSending) {
            setIsSending(true);
            const currentMessage = message;
            setTimeout(() => {
                onSendMessage(currentMessage);
                setMessage('');
                setIsSending(false);
            }, 50);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            console.log("Enter")
            event.preventDefault();
            if (formRef.current) {
                formRef.current.requestSubmit();
            }
        } else if (event.key === 'Enter' && event.shiftKey) {
            setMessage(prev => prev + '\n');
            event.preventDefault();
        }
    };

    return (
        <Box
            ref={formRef}
            component="form"
            onSubmit={handleSendMessage}
            sx={{
                display: 'flex',
                flexDirection: "row",
                backgroundColor: isDarkMode ? colors.grey[600] : '#f1f1f1',
                borderRadius: '24px',
            }}>
            <Box
                sx={{
                    p: '12px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    opacity: disabled ? 0.5 : 1,
                    maxHeight: "250px",
                    ml: 2,
                }}
            >

                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        maxHeight: '100%',
                        overflowY: 'auto',
                        width: '100%',
                    }}
                >

                    <InputBase
                        inputRef={inputRef}
                        multiline
                        sx={{
                            flex: 1, width: '100%', resize: 'none', 
                        }}
                        placeholder={notAllowContinueConversation ? "This conversation cannot be continued because a ticket has been created." : "Type a message..."}
                        inputProps={{ 'aria-label': 'Type a message', style: { lineHeight: '1.5' } }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                        disabled={disabled}
                    />

                    {message ? <Box>
                        <IconButton aria-label="clear-input" onClick={() => setMessage('')}>
                            <Close sx={{ fontSize: 14, color: '#000' }} />
                        </IconButton>
                    </Box> : null}
                </Box>
            </Box>

            <Box sx={{ display: "flex", minHeight: '50px', alignItems: "center", alignSelf: "flex-end" }}>
                <RecordingButton
                    disabled={disabled}
                    isRecording={recording}
                    onClick={recording ? stopRecording : startRecording}
                />
                <IconButton type="submit" sx={{ p: '10px' }} aria-label="send" disabled={disabled}>
                    <SendIcon sx={{ fontSize: 20, color: disabled ? 'gray' : '#000' }} />
                </IconButton>
                {/* {isTranscribing && <CircularProgress size={24} sx={{ ml: 2 }} />} */}
            </Box>


        </Box >

    );
};

export default InputBar;
