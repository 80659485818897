import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Divider, Box, useTheme } from '@mui/material';

interface CustomDialogProps {
    open: boolean;
    title: string;
    subtitle?: string;
    content: React.ReactNode;
    primaryActionText: string;
    secondaryActionText: string;
    onPrimaryAction: (event: React.MouseEvent<HTMLElement>) => void;
    onSecondaryAction: (event: React.MouseEvent<HTMLElement>) => void;
    primaryTextColor?: string; // Optional button color
    secondaryTextColor?: string; // Optional button color
    primaryButtonColor?: string; // Optional button color
    secondaryButtonColor?: string; // Optional button color
    primaryButtonHoverColor?: string; // Optional button hover color
    secondaryButtonHoverColor?: string; // Optional button hover color
    onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
    open,
    title,
    subtitle,
    content,
    primaryActionText,
    secondaryActionText,
    onPrimaryAction,
    onSecondaryAction,
    primaryTextColor = 'primary', // Default color
    secondaryTextColor = 'secondary', // Default color
    primaryButtonColor = 'primary', // Default color
    secondaryButtonColor = 'secondary', // Default color
    primaryButtonHoverColor = 'primary', // Default button hover color
    secondaryButtonHoverColor = 'secondary', // Default button hover color
    onClose,
}) => {

    const theme = useTheme()
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{ sx: { borderRadius: 2, backgroundColor: theme.palette.background.paper } }}
        >
            <DialogTitle>
                <Typography sx={{ fontWeight: 600 }} variant="h4" component="div">
                    {title}
                </Typography>
                {subtitle && (
                    <Typography variant="subtitle1" component="div" color="textSecondary">
                        {subtitle}
                    </Typography>
                )}
                <Divider sx={{ my: 1 }} />
            </DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                {secondaryActionText && (
                    <Button
                        onClick={onSecondaryAction}
                        sx={{
                            background: secondaryButtonColor, color: secondaryTextColor, '&:hover': {
                                backgroundColor: secondaryButtonHoverColor
                            },
                        }}
                    >
                        <Typography variant='h6'>
                            {secondaryActionText}
                        </Typography>
                    </Button>
                )}
                {primaryActionText && (
                    <Button
                        onClick={onPrimaryAction}
                        sx={{
                            background: primaryButtonColor, color: primaryTextColor, '&:hover': {
                                backgroundColor: primaryButtonHoverColor
                            }
                        }}
                    >
                        <Typography variant='h6'>
                            {primaryActionText}
                        </Typography>
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;
