// App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { useAuth } from './context/AuthContext'; // Import useAuth
import Home from './pages/home_page';
import ChatLogs from './pages/chat_logs';
import DocumentManagement from './pages/document_management';
import ChatBox from './components/ChatBox';
import ChatLogDetail from './components/ChatLogDetail';
import StoreToken from './components/StoreToken';
import Login from './pages/login';
import ProtectedRoute from './components/ProtectedRoute';
import SideBar from './pages/global/SideBar';
import Topbar from './pages/global/TopBar';
import { useUser } from './hook/useUser';

const App: React.FC = () => {
  const theme = useTheme();

  return (
    <Router>
      <div className='app' style={{ backgroundColor: theme.palette.background.default }}>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/storeToken" element={<StoreToken />} /> {/* Add this line */}

          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            <Route
              path="*"
              element={
                <>
                  <SideBar />
                  <div className='content' style={{ flexGrow: 1 }}>
                    <ChatBox />
                    <Topbar />
                    <Routes>
                      <Route path="/home" element={<Home />} />
                      <Route path="/ticket-logs" element={<ChatLogs />} />
                      <Route path="/document-management" element={<DocumentManagement />} />
                      <Route path="/ticket-log-detail/:ticket_id" element={<ChatLogDetail />} />
                      <Route path="/" element={<Navigate to="/home" />} />
                    </Routes>
                  </div>
                </>
              }
            />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
