export const convertToTitleCase = (str: string): string => {
    return str
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase());
};

export const getFirstDayOfCurrentMonth = () => {
    // Get the current date
    const currentDate = new Date();

    // Get the first day of the current month
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0);

    return `${firstDay.toISOString().split('T')[0]} ${firstDay.toISOString().split('T')[1].split('.')[0]}`
}

export const getLastDayOfCurrentMonth = () => {
    // Get the current date
    const currentDate = new Date();

    // Get the last day of the current month
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
    lastDay.setHours(23, 59, 59, 999)
    return `${lastDay.toISOString().split('T')[0]} ${lastDay.toISOString().split('T')[1].split('.')[0]}`
}

type Item = { [key: string]: any };

export const groupBy = <T extends Item>(
  array: T[],
  key: keyof T,
  uniqueField: keyof T // Field to identify duplicates, e.g., 'id'
): { [key: string]: { [K in keyof T]: T[K] } & { items: T[] } }[] => {
  return Object.values(
    array.reduce((acc: { [key: string]: any }, item: T) => {
      const groupKey = item[key];
      
      // Create a group if it doesn't exist
      if (!acc[groupKey]) {
        acc[groupKey] = { [key]: groupKey, items: [] };
      }
      
      // Check if the item already exists in the group by uniqueField
      const isDuplicate = acc[groupKey].items.some(
        (groupItem: T) => groupItem[uniqueField] === item[uniqueField]
      );
      
      // Add the item only if it's not a duplicate
      if (!isDuplicate) {
        acc[groupKey].items.push(item);
      }

      return acc;
    }, {})
  );
};

  