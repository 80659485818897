import React, { useState } from 'react';
import { Box, TextField, Button, Typography, useTheme, Alert, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useAuthentication } from '../../hook/useAuthentication';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Import icons for showing/hiding password

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false); // State to control password visibility
  const theme = useTheme();
  const navigate = useNavigate();
  const { setToken, setUser } = useAuth();
  const { login } = useAuthentication();

  const main_url = process.env.REACT_APP_API_BASE_URL;

  const signInWithMicrosoft = async () => {
    const response = await fetch(`${main_url}/api/v1/users/sso/login-url`);
    const data = await response.json();
    const url = data.data.url;
    window.location.href = url;
  };

  const handleLogin = async () => {
    setErrorMessage(null);

    if (!email || !password) {
      setErrorMessage('Please enter both email and password.');
      return;
    }

    try {
      const response = await login(email, password);
      if (response && response.error_code === 0) {
        const { access_token, ...user } = response.data;
        setToken(access_token);
        setUser(user);
        navigate('/home');
      } else {
        setErrorMessage('Invalid email or password.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      p: 2,
      backgroundColor: theme.palette.background.paper,
    }}>
      <Box
        width={{ base: '90%', sm: '400px' }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.background.paper,
          p: 4,
        }}
      >
        <Typography variant="h1" fontWeight={600} color={theme.palette.primary.main}>
          Sign In
        </Typography>

        {errorMessage && (
          <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
            {errorMessage}
          </Alert>
        )}

        <TextField
          label="Email/Phone Number"
          variant="outlined"
          // sx={{ background: theme.palette.primary.main}}
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          sx={{ padding: theme.spacing(2), marginTop: theme.spacing(2), backgroundColor: theme.palette.primary.main, '&:hover': { backgroundColor: '#115293' } }}
        >
          <Typography variant='h5' fontWeight={600}>
            Sign In
          </Typography>
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={signInWithMicrosoft}
          sx={{ padding: theme.spacing(2), marginTop: theme.spacing(2), borderColor: theme.palette.primary.main }}
        >
          <Typography variant='h5' fontWeight={600}>
            Sign Up with Microsoft
          </Typography>

        </Button>
      </Box>
    </Box >
  );
};

export default Login;
