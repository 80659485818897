// src/context/AuthContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';

// Define the context data shape
interface AuthContextType {
    token: string;
    user: any | null; // Define the user type accordingly
    setToken: (token: string) => void;
    setUser: (user: any | null) => void; // Function to set user data
    clearAuth: () => void; // Clear both user and token
}

// Create the context with a default value of undefined
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Custom hook to use the AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

// Provider component to wrap your app
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [token, setTokenState] = useState<string>(() => {
        // Read the token from localStorage when initializing the state
        return localStorage.getItem('authToken') || ""
    });

    const [user, setUserState] = useState<any | null>(() => {
        // Read the user from localStorage when initializing the state
        const storedUser = localStorage.getItem('authUser');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    // Function to update the token and store it in localStorage
    const setToken = (newToken: string) => {
        setTokenState(newToken);
        if (newToken) {
            localStorage.setItem('authToken', newToken);
        } else {
            localStorage.removeItem('authToken');
        }
    };

    // Function to update the user and store it in localStorage
    const setUser = (newUser: any | null) => {
        setUserState(newUser);
        if (newUser) {
            localStorage.setItem('authUser', JSON.stringify(newUser));
        } else {
            localStorage.removeItem('authUser');
        }
    };

    // Function to clear both token and user
    const clearAuth = () => {
        setToken("");
        setUser("");
    };

    // Optional: listen for changes in localStorage (for multi-tab use)
    useEffect(() => {
        const handleStorageChange = () => {
            const storedToken = localStorage.getItem('authToken') || ""
            const storedUser = localStorage.getItem('authUser') || ""
            if (storedToken !== token) {
                setTokenState(storedToken);
            }
            if (storedUser) {
                setUserState(JSON.parse(storedUser));
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [token, user]);

    return (
        <AuthContext.Provider value={{ token, user, setToken, setUser, clearAuth }}>
            {children}
        </AuthContext.Provider>
    );
};
