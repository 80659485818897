// src/contexts/ProcessingDialogContext.tsx

import React, { createContext, useContext, useState } from 'react';

interface ProcessingDialogContextProps {
  open: boolean;
  title: string;
  message: string;
  setDialog: (open: boolean, title: string, message: string) => void;
}

const ProcessingDialogContext = createContext<ProcessingDialogContextProps | undefined>(undefined);

export const useProcessingDialog = () => {
  const context = useContext(ProcessingDialogContext);
  if (!context) {
    throw new Error('useProcessingDialog must be used within a ProcessingDialogProvider');
  }
  return context;
};

export const ProcessingDialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('Processing');
  const [message, setMessage] = useState('');

  const setDialog = (open: boolean, title: string, message: string) => {
    setOpen(open);
    setTitle(title);
    setMessage(message);
  };

  return (
    <ProcessingDialogContext.Provider value={{ open, title, message, setDialog }}>
      {children}
    </ProcessingDialogContext.Provider>
  );
};
