import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface ApiRequestParams {
    token?: string;
    signal?: AbortSignal;
    endpoint: string; // Endpoint relative to base URL (e.g., '/api/v1/kb_conversations')
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE'; // HTTP method, default is 'GET'
    queryParams?: Record<string, string | number>; // Query parameters for the URL
    body?: any; // Request body for POST/PUT requests
    headers?: Record<string, string>; // Additional headers (if needed)
    transformResponse?: (res: any) => any;  // Optional transform function
}

export const useApiRequest = () => {
    const { token } = useAuth();
    const navigate = useNavigate();

    const apiRequest = async ({ token: tokenPass, signal, endpoint, method = 'GET', queryParams = {}, body, headers = {}, transformResponse }: ApiRequestParams): Promise<any> => {
        const url = new URL(`${API_BASE_URL}${endpoint}`);
        
        // Append query parameters to URL
        Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key].toString()));

        // Set default headers (Authorization, Content-Type)

        const defaultHeaders: Record<string, string> = {
            'Authorization': tokenPass || token,  // Directly using token without 'Bearer ' prefix
            'Content-Type': 'application/json',
            ...Object.fromEntries(Object.entries(headers).map(([key, value]) => [key, String(value)])) // Ensure all values are strings
        };

        // Create request options
        const options: RequestInit = {
            method,
            headers: defaultHeaders,
            signal,
        };

        // Add body for POST/PUT requests
        if (body) {
            options.body = JSON.stringify(body);
        }

        try {
            const response = await fetch(url.toString(), options);
            // Check for invalid token error and navigate to /login
            if (response.status === 401) {
                const responseData = await response.json();
                if (responseData.error_code === 2 && responseData.message === "Invalid token") {
                    navigate("/login");
                    return; // Early return to stop further processing
                }
            }

            // If there's a transformResponse function, apply it
            if (transformResponse) {
                return transformResponse(response);
            }

            return response.json();
        } catch (error) {
            console.error("API request error:", error);

            // Check if the error is an instance of Response or an unknown error
            if (error instanceof Error) {
                // Handle known error
                const errorMessage = error.message || 'An error occurred during the request';
                throw new Error(errorMessage);
            } else {
                // Handle unexpected error
                throw new Error('An unexpected error occurred');
            }
        }
    };

    return { apiRequest };
};
