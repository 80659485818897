// src/hooks/useConversations.ts
import { useApiRequest } from './useApiRequest';

interface DocumentListParams {
    signal?: AbortSignal;
    page?: number;
    page_size?: number;
    is_assigned?: boolean;
    from_date?: string;
    to_date?: string;
    status?: string;
    last_conversation_id?: string;
    order_by?: string;
}


export const useDocumentList = () => {
    const { apiRequest } = useApiRequest();

    const fetchDocumentList = async ({
        signal,
        page,
        page_size = 20,
    }: DocumentListParams): Promise<any> => {
        const queryParams: Record<string, string | number> = {};
        if (page !== undefined) queryParams.page = page;
        if (page_size !== undefined) queryParams.page_size = page_size;

        return apiRequest({
            signal,
            endpoint: '/api/v1/knowledge_base/knowledge_base',
            queryParams,
            transformResponse: (response) =>
                response.json().then((data: any) => {
                    return data.data
                }),
        });
    };

    return { fetchDocumentList };
};
