import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TextCell from './TextCell'; // Assuming this is for rendering text cells


interface LastChatMessageCellProps {
    message: string;
    ticketId: string;
    conversationId: string;
    ticketData: any;
    ticketUrl: string;
    currentPage: number; // Pass the current page state as a prop
    selectedTab?: number;
}

const LastChatMessageCell: React.FC<LastChatMessageCellProps> = ({ message, ticketData, ticketId, conversationId, ticketUrl, currentPage, selectedTab }) => {
    const navigate = useNavigate();
    const handleViewDetail = () => {
        // Navigate to the detail page with the ticket_id
        navigate(`/ticket-log-detail/${ticketId}`, {
            state: { currentPage, selectedTab, conversationId, ticketData },
        });
    };

    const handleGoToTicket = () => {
        if (ticketUrl) {
            window.open(ticketUrl, '_blank'); // Opens URL in a new tab
        }
    };

    return (
        <Box sx={{
            display: "flex",
            height: 100,
            flexDirection: "column",
            justifyContent: "center",
            overflow: "hidden",
            px: 1,
            py: 2
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleViewDetail}
                    style={{ fontSize: 10 }}
                >
                    View Detail
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleGoToTicket}
                    style={{ marginLeft: 10, fontSize: 10 }}
                >
                    Go to ticket
                </Button>
            </Box>

        </Box>
    );
};

export default LastChatMessageCell;