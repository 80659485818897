// src/pages/AdminChatLogs.tsx
import React from 'react';
import { Box, Typography, Tabs, Tab, useTheme } from '@mui/material';
import ChatLogDataGrid from '../../components/ChatLogsDataGrid';
import Pagination from '../../components/Pagination';

const AdminChatLogs: React.FC<{
    currentPage: number;
    totalPages: number;
    selectedTab: number;
    tickets: any[];
    onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
    onPageChange: (page: number) => void;
}> = ({ currentPage, totalPages, selectedTab, tickets, onTabChange, onPageChange }) => {
    const theme = useTheme();

    return (
        <Box>
            <Typography variant='h3' mb={"16px"}>
                Listing ticket logs
            </Typography>
            <Box sx={{ display: "flex" }}>
                <Tabs
                    value={selectedTab}
                    onChange={onTabChange}
                    centered
                    sx={{
                        border: `1px solid ${theme.palette.grey[400]}`,
                        borderRadius: '8px',
                        '& .MuiTabs-indicator': {
                            display: 'none',
                        },
                    }}
                >
                    <Tab
                        label="Unsigned"
                        sx={{
                            backgroundColor: selectedTab === 0 ? theme.palette.primary.main : 'transparent',
                            fontWeight: 'bold',
                            borderRadius: '8px 0 0 8px',
                            '&.Mui-selected': {
                                color: 'white', // Ensure white text for the selected tab
                            },
                            '&:hover': {
                                backgroundColor: selectedTab === 0 ? theme.palette.primary.main : theme.palette.grey[200],
                            },
                        }}
                    />
                    <Tab
                        label="Assigned"
                        sx={{
                            backgroundColor: selectedTab === 1 ? theme.palette.primary.main : 'transparent',
                            fontWeight: 'bold',
                            borderRadius: '0 8px 8px 0',
                            '&.Mui-selected': {
                                color: 'white', // Ensure white text for the selected tab
                            },
                            '&:hover': {
                                backgroundColor: selectedTab === 1 ? theme.palette.primary.main : theme.palette.grey[200],
                            },
                        }}
                    />
                </Tabs>
            </Box>
            <Box
                m="20px 0"
                sx={{
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: theme.palette.primary.main,
                    },
                    "& .MuiCheckbox-root": {
                        color: `${theme.palette.secondary.dark} !important`,
                    },
                    "& .MuiDataGrid-root": {
                        backgroundColor: `${theme.palette.background.paper} !important`,
                    },
                }}
            >
                <ChatLogDataGrid dataChatLogs={tickets} currentPage={currentPage} selectedTab={selectedTab} />
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={onPageChange} />
            </Box>
        </Box>
    );
};

export default AdminChatLogs;
