import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ColorModeProvider } from './context/ColorModeContext';
import {
  QueryClientProvider,
} from '@tanstack/react-query'
import { queryClient } from './utils/http';
import { AuthProvider } from './context/AuthContext';
import { ProcessingDialogProvider } from './context/ProcessingDialogContext';

// Debounce function to prevent continuous resize events
const debounce = <T extends (...args: any[]) => void>(fn: T, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
};

const observeResizes = () => {
  const observer = new ResizeObserver(
    debounce((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        console.log(entry.contentRect);
        // Add resize logic here
      }
    }, 250)
  );

  const element = document.getElementById('root');
  if (element) {
    observer.observe(element);
  }

  return () => observer.disconnect();
};

observeResizes();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ColorModeProvider>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ProcessingDialogProvider>
          <App />
        </ProcessingDialogProvider>
      </QueryClientProvider>
    </AuthProvider>
  </ColorModeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
