import React, { useState } from 'react';
import { Box, IconButton, InputBase, Menu, MenuItem, Typography } from "@mui/material";
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useColorMode, colors } from "../../context/ColorModeContext";
import { useAuthentication } from '../../hook/useAuthentication';
import ProcessingDialog from '../../components/ProcessingDialog';
import { useProcessingDialog } from '../../context/ProcessingDialogContext';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Topbar = () => {
  const { toggleColorMode, mode  } = useColorMode();
  const { user } = useAuth()
  const { setDialog } = useProcessingDialog();
  const isDarkMode = mode === "dark";
  const {logout} = useAuthentication();
  const navigate = useNavigate()

  // State to handle the settings menu
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);
  const isSettingsMenuOpen = Boolean(settingsAnchorEl);

  const handleSettingsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsAnchorEl(event.currentTarget);  // Open the settings menu
  };

  const handleSettingsMenuClose = () => {
    setSettingsAnchorEl(null);  // Close the settings menu
  };

  // State to handle the person menu
  const [personAnchorEl, setPersonAnchorEl] = useState<null | HTMLElement>(null);
  const isPersonMenuOpen = Boolean(personAnchorEl);

  const handlePersonMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPersonAnchorEl(event.currentTarget);  // Open the person menu
  };


  const handlePersonMenuClose = () => {
    setPersonAnchorEl(null);  // Close the person menu
  };

  const handleSelectClientId = () => {
    navigate('/storeToken')
  }

  return (
    <Box display={"flex"} justifyContent={"flex-end"} p={2}>
      {/* SEARCH BAR */}
      <Box
        display={"flex"}
        bgcolor={isDarkMode ? colors.primary[400] : colors.primary[100]}
        borderRadius="3px"
        mr={"20px"}
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchOutlinedIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display={"flex"}>
        <IconButton onClick={toggleColorMode}>
          {isDarkMode ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleSettingsMenuOpen}>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handlePersonMenuOpen}>
          <PersonOutlinedIcon />
        </IconButton>

        {/* Settings Menu */}
        <Menu
          anchorEl={settingsAnchorEl}
          open={isSettingsMenuOpen}
          onClose={handleSettingsMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() => logout(setDialog)}
            sx={{
              bgcolor: 'inherit',
              fontWeight: 'normal',
            }}
          >
            <Typography>Logout</Typography>
          </MenuItem>
          <ProcessingDialog />
        </Menu>

        {/* Person Menu */}
        <Menu
          anchorEl={personAnchorEl}
          open={isPersonMenuOpen}
          onClose={handlePersonMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() => handleSelectClientId()}
            sx={{
              bgcolor: 'inherit',
              fontWeight: 'normal',
            }}
          >
            <Typography>{`Client ID: ${user.client_id}`}</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;
