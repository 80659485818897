import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, sidebarClasses } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { useColorMode, colors } from "../../context/ColorModeContext";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const SideBar = () => {
    const theme = useTheme()
    const { mode } = useColorMode()
    const [collapsed, setCollapsed] = useState(false);

    const [toggled, setToggled] = useState(false);

    const location = useLocation();

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
    };
    const handleToggleSidebar = (value: any) => {
        setToggled(value);
    };

    return (
        <Sidebar
            collapsed={collapsed}
            toggled={toggled}
            rootStyles={{
                [`.${sidebarClasses.container}`]: {
                    backgroundColor: theme.palette.background.default,
                },

            }}
        >
            <Box sx={{
                display: 'flex', 
                justifyContent: 'center', 
                mt: 1, 
                alignItems: 'center', 
            }}>
                <Box
                    component="img"
                    sx={{
                        width: 200,
                    }}
                    alt="My Image"
                    src={mode === 'light' ? "/VeryPay_Original.png" : "/VeryPay_White.png"}
                />
            </Box>

            <Menu
                menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                        // Apply styles based on the active state
                        if (level === 0) {
                            return {
                                color: theme.palette.text.primary,
                                backgroundColor: active ? theme.palette.primary.main : undefined, // Change background when active
                                ":hover": {
                                    backgroundColor: active
                                        ? theme.palette.primary.main
                                        : theme.palette.primary.light, // Background when hovering
                                },
                                transition: "background-color 0.3s ease",
                            };
                        }
                    },
                }}
            >
                <MenuItem icon={<HomeOutlinedIcon />} active={location.pathname === "/home"} component={<Link to="/home" />}>Home</MenuItem>
                <MenuItem icon={<ChatOutlinedIcon />} active={location.pathname === "/ticket-logs"} component={<Link to="/ticket-logs" />}>Ticket Logs </MenuItem>
                <MenuItem icon={<AssignmentOutlinedIcon />} active={location.pathname === "/document-management"} component={<Link to="/document-management" />}>Document management</MenuItem>
            </Menu>
        </Sidebar>

    )
}

export default SideBar;