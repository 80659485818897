// src/pages/Home.tsx
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import AdminChatLogs from './AdminChatLogs';
import CSAgentChatLogs from './CSAgentChatLogs';
import { useTickets } from '../../hook/useTickets';
import { useAuth } from '../../context/AuthContext';

const ChatLogs: React.FC = () => {
    const {user} = useAuth();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState<number>(location.state?.currentPage || 1);

    // Use state for selected tab and page for each tab
    const [selectedTab, setSelectedTab] = useState<number>(location.state?.selectedTab || 0);

    const pageSize = 5;

    const { fetchTickets } = useTickets()

    // Fetch tickets based on the selected tab and page
    const fetchTicketsByRole = (role: string) => {
        if (role === 'admin') {
            // Admin fetches all tickets based on selected tab (unsigned or assigned)
            return fetchTickets({
                is_assigned: selectedTab === 1,
                page: currentPage,
                page_size: pageSize
            });
        } else if (role === 'cs_agent') {
            // CS Agent fetches only assigned tickets to that specific agent
            return fetchTickets({
                // agentId,  // Pass the specific CS Agent's ID
                is_assigned: true,
                page: currentPage,
                page_size: pageSize
            });
        }
    }; 

    const { data, isLoading, isError, error } = useQuery({
        queryKey: ['tickets', selectedTab, currentPage, user],
        queryFn: () => fetchTicketsByRole(user.role),
    });


    const totalPages = data?.totalPages || 0;  // Adjust based on API response
    const tickets = data?.tickets || [];  // Your data model

    // Handle tab change
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
        setCurrentPage(1);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    return (
        <Box m="0 20px 20px 20px">
            {user.role === 'admin' ? (
                <AdminChatLogs
                    currentPage={currentPage}
                    totalPages={totalPages}
                    selectedTab={selectedTab}
                    tickets={tickets}
                    onTabChange={handleTabChange}
                    onPageChange={handlePageChange}
                />
            ) : (
                <CSAgentChatLogs
                    currentPage={currentPage}
                    totalPages={totalPages}
                    tickets={tickets}
                    onPageChange={handlePageChange}
                />
            )}
        </Box>
    );
};

export default ChatLogs;
