import React, { useEffect, useState, useRef } from 'react';
import { Box, IconButton, Button, Typography, InputBase, useTheme } from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ConversationList from './ConversationList';
import ConversationChat from './ConversationChat';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useColorMode } from '../context/ColorModeContext';
import { useConversations } from '../hook/useConversations';
import DraggableButton from './DraggableButton';

const ChatBox: React.FC = () => {
    const theme = useTheme()
    const { mode } = useColorMode();
    const isDarkMode = mode === "dark";

    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isCreatedTicket, setcreatedTicket] = useState<boolean>(false);
    const [selectedConversationId, setSelectedConversationId] = useState<string | null>(null);
    const dataRef = useRef<any>();
    const conversationListRef = useRef<HTMLDivElement>(null);

    const { fetchConversations } = useConversations()

    const fetchConversation = async ({ pageParam }: { pageParam?: string }) => {
        const response = await fetchConversations({
            last_conversation_id: pageParam,
            signal: new AbortController().signal
        });

        return response;
    };

    const {
        data,
        error,
        isFetching,
        hasNextPage,
        fetchNextPage
    } = useInfiniteQuery({
        queryKey: ['conversations'],
        queryFn: fetchConversation,
        initialPageParam: undefined,
        getNextPageParam: (lastPage, pages) => {
            if (lastPage && lastPage.length > 0) {
                const lastConversation = lastPage[lastPage.length - 1];
                return lastConversation ? lastConversation.id : undefined;
            }
        },
        getPreviousPageParam: (firstPage, pages) => {
            return undefined;
        },
    })

    useEffect(() => {
        if (data) {
            dataRef.current = [...data.pages.flatMap(page => page)]
        }
    }, [data])

    const selectNewConversationCreated = () => {
        if (dataRef.current) {
            setSelectedConversationId(dataRef.current[0].id)
            setcreatedTicket(dataRef.current[0].ticket_flag)
        }
    }

    const handleCreateConversation = () => {
        setSelectedConversationId(null)
        setcreatedTicket(false)

        scrollToTop()
    };

    const handleSelectConversation = (id: string, isCreatedTicket: boolean) => {
        setSelectedConversationId(id);
        setcreatedTicket(isCreatedTicket)

    };

    const handleClickChatBoxButton = () => {
        setIsChatOpen(prev => !prev)
    }

    const handleCloseChat = () => {
        setIsChatOpen(false);
    };


    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const bottom = event.currentTarget.scrollHeight <= event.currentTarget.scrollTop + event.currentTarget.clientHeight + 100;

        if (bottom && hasNextPage && !isFetching) {
            fetchNextPage();
        }
    };

    const scrollToTop = () => {
        if (conversationListRef.current) {
            conversationListRef.current.scrollTo({
                top: 0,
            });
        }
    };

    let content;

    if (isFetching) {
        content = <Typography variant='h4'>Loading...</Typography>
    }

    if (error) {
        content = <Typography variant='h4'>{error?.message}</Typography>
    }

    if (data) {
        content = <Box ref={conversationListRef} onScroll={handleScroll} sx={{ overflowY: 'auto' }}>
            <ConversationList
                conversations={[...data.pages.flatMap(page => page)]}
                selectedConversationId={selectedConversationId}
                onSelectConversation={handleSelectConversation}
                handleCreateConversation={handleCreateConversation}
            />
        </Box>
    }


    return (
        <>
            <DraggableButton
                onClick={handleClickChatBoxButton}
                style={{
                    background: '#d6262e',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
                    padding: 20,
                }}
            >
                <SmartToyOutlinedIcon fontSize="large" sx={{ color: 'white' }} />
            </DraggableButton>

            {isChatOpen && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 80,
                        right: 80,
                        width: '75vw',
                        height: '80vh',
                        borderRadius: '12px',
                        p: 1,
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex: 1300,
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            sx={{
                                width: '300px',
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: "12px",
                                background: isDarkMode ? theme.palette.background.default : "#f5f5f5",
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: "row",
                                p: 1,
                                justifyContent: "flex-end",
                            }}>

                                {/* <Box
                                    bgcolor={isDarkMode ? colors.grey[600] : '#fff'}
                                    borderRadius="3px"
                                    sx={{ display: "flex", flex: 1, mr: 1 }}
                                >
                                    <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                                    <IconButton type="button" sx={{ p: 1 }}>
                                        <SearchOutlinedIcon />
                                    </IconButton>
                                </Box> */}

                                <IconButton
                                    onClick={handleCreateConversation}
                                >
                                    <EditOutlinedIcon fontSize="medium" />
                                </IconButton>

                            </Box>
                            {content}
                        </Box>
                        <ConversationChat
                            handleCloseChat={handleCloseChat}
                            selectNewConversationCreated={selectNewConversationCreated}
                            conversationId={selectedConversationId}
                            isCreatedTicket={isCreatedTicket}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ChatBox;
