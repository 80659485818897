// src/hooks/useConversations.ts
import { useApiRequest } from './useApiRequest';

interface FetchTicketsParams {
    signal?: AbortSignal;
    page?: number;
    page_size?: number;
    is_assigned?: boolean;
    from_date?: string;
    to_date?: string;
    status?: string;
    last_conversation_id?: string;
    order_by?: string;
}

interface SelectTicketParams {
    ticket_id: string;
}

export const useTickets = () => {
    const { apiRequest } = useApiRequest();

    const fetchTickets = async ({
        signal,
        page,
        page_size = 20,
        status,
        is_assigned,
        from_date,
        to_date
    }: FetchTicketsParams): Promise<any> => {
        const queryParams: Record<string, string | number> = {};
        if (page !== undefined) queryParams.page = page;
        if (page_size !== undefined) queryParams.page_size = page_size;
        if (status !== undefined) queryParams.status = status;
        if (from_date !== undefined) queryParams.from_date = from_date;
        if (to_date !== undefined) queryParams.to_date = to_date;

        // Convert boolean to string
        if (is_assigned !== undefined) queryParams.is_assigned = is_assigned ? 'true' : 'false';
        return apiRequest({
            signal,
            endpoint: '/api/v1/tickets',
            queryParams,
            transformResponse: (response) =>
                response.json().then((data: any) => {
                    return {
                        tickets: data.data, // Array of tickets
                        totalPages: Math.ceil(data.total / page_size),
                    };
                }),
        });
    };

    const selectTicket = async ({ ticket_id }: SelectTicketParams): Promise<any> => {
        return apiRequest({
            method: 'POST',
            body: { ticket_id },
            endpoint: '/api/v1/tickets/select',
            transformResponse: (response) =>
                response.json().then((data: any) => {
                    return data.data;
                }),
        });
    };

    return { fetchTickets, selectTicket };
};
