import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

interface StackedBarChartProps {
    data: any[];
    keys: string[];
    indexBy: string;
    legendTitle: string;
    axisBottomLabel?: string;
    axisLeftLabel?: string;
}

const StackedBarChart: React.FC<StackedBarChartProps> = ({
    data,
    keys,
    indexBy,
    legendTitle,
    axisBottomLabel = 'Category',
    axisLeftLabel = 'Number of Tickets',
}) => {
    return (
        <div style={{ height: '700px', width: '100%' }}> {/* Ensure there is a height and width */}
            <ResponsiveBar
                data={data}
                keys={keys}
                indexBy={indexBy}
                margin={{ top: 50, right: 300, bottom: 50, left: 60 }}
                padding={0.3}
                groupMode="stacked"
                colors={{ scheme: 'blues' }}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: axisLeftLabel,
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
                animate={true}
                motionConfig="gentle"
            />
        </div>
    );
};

export default StackedBarChart;
