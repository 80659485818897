import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../utils/http';
import { useTickets } from './useTickets';

const useSelectTicket = (currentPage: number) => {
    const { selectTicket } = useTickets()
    return useMutation({
        mutationFn: selectTicket,
        onMutate: (selectTicket) => {
            queryClient.setQueryData(['active-tickets', currentPage], (oldData: any) => {

                if (!oldData) return oldData;

                const updatedTickets = oldData.tickets.filter((ticket: any) =>
                    ticket.ticket_id !== selectTicket.ticket_id
                )

                return {
                    ...oldData,
                    tickets: updatedTickets,
                };
            })
        }
    })

}

export default useSelectTicket;