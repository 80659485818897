// src/pages/chat_log_detail.tsx
import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import ConversationChat from './ConversationChat';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
const ChatLogDetail: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const { conversationId, ticketData, selectedTab, currentPage } = location.state;

    // Back button handler
    const handleBack = () => {

        navigate('/ticket-logs', {
            state: {
                selectedTab: selectedTab || 0,
                currentPage: currentPage || 0, // Pass the current page back
            },
        });
    };

    const handleGoToTicket = () => {
        if (ticketData?.ticket_url) {
            window.open(ticketData.ticket_url, '_blank'); // Opens URL in a new tab
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: "80vh" }} m={4}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                <IconButton type="button" sx={{ p: 1 }} onClick={handleBack}>
                    <ArrowBackIosNewOutlinedIcon />
                    <Typography>
                        Back
                    </Typography>
                </IconButton>
                <IconButton type="button" sx={{ p: 1 }} onClick={handleGoToTicket}>
                    <Typography>
                        Go to ticket
                    </Typography>
                </IconButton>
            </Box>

            <Box sx={{
                display: 'flex', flex: 1,
                p: 2,
                overflow: 'hidden',
                borderWidth: 1,
                background: theme.palette.background.paper
            }}>
                <ConversationChat
                    selectNewConversationCreated={() => { }}
                    conversationId={conversationId ?? null}
                    hideInputBar={true}
                />
                <Box sx={{ marginLeft: 2, maxWidth: "25%" }}>
                    <Box sx={{ border: '1px solid black', p: 1, mb: 1 }}>
                        <Typography variant='h5' fontWeight={"600"}>User Information (Coming soon)</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
                            <Avatar
                                alt={"John Doe"}
                                src=""
                                sx={{ width: 32, height: 32 }} // Adjust size if needed
                            />
                            {/* <Typography variant="h6" fontWeight={"600"} sx={{ marginLeft: 2 }}>
                                {"John Doe"}
                            </Typography> */}
                        </Box>
                        <Typography sx={{ mt: 1 }}>Email:</Typography>
                        <Typography sx={{ mt: 1 }}>Account Created:</Typography>
                        <Typography sx={{ mt: 1 }}>Last Active:</Typography>

                    </Box>

                    <Box sx={{ border: '1px solid black', p: 1, mb: 1 }}>
                        <Typography variant="h6" fontWeight={"600"}>
                            {"Ticket Detail"}
                        </Typography>
                        <Typography sx={{ mt: 1 }}>{ticketData?.description || ""}</Typography>


                    </Box>

                    <Typography variant="h4" fontWeight={"600"} sx={{ mt: 2, mb: 1 }}>
                        Relevant Documents (Coming soon)
                    </Typography>

                    <Box sx={{ border: '1px solid black', p: 1, mb: 1 }}>
                        <Typography variant="h6" fontWeight={"600"}>
                            User Guide (Coming soon)
                        </Typography>
                        <Typography sx={{ mt: 1 }}>Comprehensive user guide to help you navigate the platform effectively.</Typography>
                        <Button
                            variant="contained"
                            color="inherit"
                            size="small"
                            onClick={() => { }}
                            style={{ marginTop: 10, fontSize: 12 }}>
                            View document
                        </Button>
                    </Box>

                    <Box sx={{ border: '1px solid black', p: 1, mb: 1 }}>
                        <Typography variant="h6" fontWeight={"600"}>
                            Relevant Tickets (Coming soon)
                        </Typography>
                        <Typography sx={{ mt: 1 }}></Typography>
                        <Button
                            variant="contained"
                            color="inherit"
                            size="small"
                            onClick={() => { }}
                            style={{ marginTop: 10, fontSize: 12 }}>
                            Read
                        </Button>
                    </Box>
                </Box>
            </Box>

            {/* Render the rest of the chat log details here */}
        </Box>
    );
};

export default ChatLogDetail;
