import React, { useState, useEffect } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';

interface DraggableButtonProps extends IconButtonProps {
  initialPosition?: { x: number; y: number }; // Optional initial position
}

const DraggableButton: React.FC<DraggableButtonProps> = ({
  initialPosition,
  children,
  style,
  ...props
}) => {

  const getDefaultPosition = () => {
    const buttonWidth = 100;
    const buttonHeight = 100;
    return {
      x: window.innerWidth - buttonWidth - 16,
      y: window.innerHeight - buttonHeight - 16,
    };
  };

  const [position, setPosition] = useState(initialPosition || getDefaultPosition());
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsDragging(true);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      const newX = e.clientX - 50;
      const newY = e.clientY - 50;
      setPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <IconButton
      onMouseDown={handleMouseDown}
      style={{
        zIndex: 1000,
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        cursor: 'grab',
        ...style,
      }}
      {...props}
    >
      {children}
    </IconButton>
  );
};

export default DraggableButton;
