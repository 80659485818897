// src/components/RecordingButton.tsx
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import { keyframes } from '@emotion/react';

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.5; }
  100% { transform: scale(1); opacity: 1; }
`;

const RecordingButton: React.FC<{ isRecording: boolean; disabled: boolean; onClick: () => void }> = ({ isRecording, disabled, onClick }) => {
  return (
    <Tooltip title={isRecording ? 'Stop Recording' : 'Start Recording'}>
      <IconButton
        disabled={disabled}
        onClick={onClick}
        sx={{
          position: 'relative',
          color: isRecording ? 'red' : 'inherit',
          '&:hover': {
            backgroundColor: isRecording ? 'rgba(255, 0, 0, 0.1)' : 'inherit',
          },
        }}
      >
        <MicIcon />
        {isRecording && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              background: 'rgba(255, 0, 0, 0.3)',
              transform: 'translate(-50%, -50%)',
              animation: `${pulseAnimation} 1s infinite`,
              zIndex: 1,
            }}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default RecordingButton;
