// src/pages/Home.tsx
import React, { useState } from 'react';
import { Box, Typography, useTheme, Grid } from '@mui/material';
import Header from '../../components/Header';
import ChartBox from '../../components/ChartBox';
import TicketsDataGrid from '../../components/LastTicketsDataGrid';
import { useQuery } from '@tanstack/react-query';
import { useTickets } from '../../hook/useTickets';
import StackedBarChart from '../../components/StackedBarChart';
import { getFirstDayOfCurrentMonth, getLastDayOfCurrentMonth } from '../../utils/common';

const AdminHome: React.FC = () => {
  const theme = useTheme()
  const { fetchTickets } = useTickets()
  // Fetch tickets based on the selected tab and page

  const { data } = useQuery({
    queryKey: ['tickets'],
    queryFn: () => fetchTickets({
      page: 1,
      page_size: 100,
      from_date: getFirstDayOfCurrentMonth(),
      to_date: getLastDayOfCurrentMonth()
    }),
  });


  const tickets = data?.tickets || [];  // Your data model

  const ticketsOpen = tickets.filter((ticket: any) => ticket.status === 'Open').length
  const ticketsWaitingForSupport = tickets.filter((ticket: any) => ticket.status === 'Waiting for support').length
  const ticketsWaitingForCustomer = tickets.filter((ticket: any) => ticket.status === 'Waiting for customer').length
  const ticketsServiceRestored = tickets.filter((ticket: any) => ticket.status === 'Service Restored ? Y/N').length
  const ticketsInProgress = tickets.filter((ticket: any) => ticket.status === 'In Progress').length
  const ticketsCancelRequest = tickets.filter((ticket: any) => ticket.status === 'Cancel request').length
  const ticketsMajorIncident = tickets.filter((ticket: any) => ticket.status === 'Major Incident').length
  const ticketsPending = tickets.filter((ticket: any) => ticket.status === 'Pending').length
  const ticketsResolved = tickets.filter((ticket: any) => ticket.status === 'Resolved').length
  const ticketsClosed = tickets.filter((ticket: any) => ticket.status === 'Closed ticket & update knowledge base').length

  const chartData = [
    {
      "Open": ticketsOpen,
      "Waiting for support": ticketsWaitingForSupport,
      "Waiting for customer": ticketsWaitingForCustomer,
      "Service Restored ? Y/N": ticketsServiceRestored,
      "In Progress": ticketsInProgress,
      "Cancel request": ticketsCancelRequest,
      "Major Incident": ticketsMajorIncident,
      "Pending": ticketsPending,
      "Resolved": ticketsResolved,
      "Closed ticket & update knowledge base": ticketsClosed
    },
  ];

  const currentDate = new Date()
  const dateString = currentDate.toDateString().split(' ')
  
  return (
    <Box>
      <Box>
        <Typography variant='h3' mb={"16px"} color={theme.palette.text.primary}>
          {`Summary (${dateString[1]} ${dateString[3]})`}
        </Typography>
        <StackedBarChart
          data={chartData}
          keys={[
            "Open",
            "Waiting for support",
            "Waiting for customer",
            "Service Restored ? Y/N",
            "In Progress",
            "Cancel request",
            "Major Incident",
            "Pending",
            "Resolved",
            "Closed ticket & update knowledge base"
          ]}
          indexBy="date"
          legendTitle="Ticket Status"
          axisBottomLabel="Date"
          axisLeftLabel="Number of Tickets"
        />
        {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {Array.from(Array(6)).map((_, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <ChartBox
                title={`Chats handled by AI to completion`}
                subtitle={`390+`}
                chartHeight={'100px'}
                chartWidth="50%"
              />
            </Grid>
          ))}
        </Grid> */}
      </Box>

      {/* LASTKETS */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        m="20px 0 0 0"
        height={"46.6vh"}
        sx={{
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: theme.palette.primary.main,
            borderBottom: "none",
          },
          '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
            color: theme.palette.background.paper, // Color for the checkbox in the header
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: theme.palette.text.primary,
            ...theme.typography.h5
            // Set the desired color for the text in the header row
          },
        }}
      >
        {/* <Typography variant='h3' mb={"20px"} color={theme.palette.text.primary}>
          Last tickets
        </Typography>
        <TicketsDataGrid ticketsData={tickets} currentPage={currentPage} /> */}
      </Box>
    </Box>
  );
};

export default AdminHome;
