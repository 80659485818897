import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, List, ListItem, FormControlLabel, Radio, Button, useTheme } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../hook/useUser';
import { useProcessingDialog } from '../context/ProcessingDialogContext';
import ProcessingDialog from './ProcessingDialog';

const StoreToken = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    const { user, token, setToken, setUser } = useAuth();
    const { getUserInfo, getClientId, updateClientId } = useUser()
    const { setDialog } = useProcessingDialog();
    const [clientIds, setClientIds] = useState<string[]>([]);
    const [selectedClientId, setSelectedClientId] = useState<string | null>(null);

    useEffect(() => {
        const fetchAuthentication = async () => {
            const urlParams = new URLSearchParams(location.search);
            const token = urlParams.get('token') || "";
            if (token) {
                setToken(token);
            }

            try {

                const [user, clientIdList] = await Promise.all([getUserInfo({ token }), getClientId({ token })])
                setClientIds(clientIdList); // Set the client IDs list
                setUser(user); // Set the client IDs list

                if (user?.role === 'admin' || !clientIdList || clientIdList.length <= 0) {
                    navigate('/');
                }
            } catch (error) {
                console.error("Error fetching authentication:", error);
                // Handle error
            }
        };

        fetchAuthentication();
    }, [location, navigate]);

    const handleClientIdSelect = (clientId: string) => {
        setSelectedClientId(clientId);
    };

    const handleSubmit = async () => {
        if (selectedClientId) {
            setDialog(true, '', '');
            const response = await updateClientId({ clientId: selectedClientId })
            setDialog(false, '', '')
            if (response.error_code === 0) {
                const user = await getUserInfo({ token })
                setUser(user)
                navigate('/');
            }
        }
    };

    if (user?.role === 'cs_agent') {
        return (
            <Box sx={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
                backgroundColor: theme.palette.background.paper,
            }}>
                <Box
                    width={{ base: '90%', sm: '400px' }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.background.paper,
                        p: 4,
                    }}
                >
                    <Typography variant="h1" fontWeight={600} color={theme.palette.primary.main}>
                        Select Client ID
                    </Typography>

                    <List>
                        {clientIds.map((clientId) => (
                            <ListItem key={clientId}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={selectedClientId === clientId}
                                            onChange={() => handleClientIdSelect(clientId)}
                                        />
                                    }
                                    label={clientId}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            color: selectedClientId === clientId ? 'primary.main' : 'text.secondary',
                                            fontWeight: selectedClientId === clientId ? 'bold' : 'bold',
                                            fontSize: 16, // Example size
                                        },
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Button onClick={handleSubmit} variant="contained" sx={{ px: 5 }} color="primary" disabled={!selectedClientId}>
                        Submit
                    </Button>
                </Box>
                <ProcessingDialog />
            </Box >
        );
    }

    return null

};

export default StoreToken;
