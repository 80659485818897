import React, { createContext, useState, useMemo, useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

export const colors = {
  grey: {
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414"
  },
  primary: {
    100: "#d0d1d5",
    200: "#a1a4ab",
    300: "#727681",
    400: "#1F2A40",
    500: "#141b2d",
    600: "#101624",
    700: "#0c101b",
    800: "#080b12",
    900: "#040509"
  },
  greenAccent: {
    100: "#dbf5ee",
    200: "#b7ebde",
    300: "#94e2cd",
    400: "#70d8bd",
    500: "#4cceac",
    600: "#3da58a",
    700: "#2e7c67",
    800: "#1e5245",
    900: "#0f2922"
  },
  redAccent: {
    100: "#f8dcdb",
    200: "#f1b9b7",
    300: "#e99592",
    400: "#e2726e",
    500: "#db4f4a",
    600: "#af3f3b",
    700: "#832f2c",
    800: "#58201e",
    900: "#2c100f"
  },
  blueAccent: {
    100: "#e1e2fe",
    200: "#c3c6fd",
    300: "#a4a9fc",
    400: "#868dfb",
    500: "#6870fa",
    600: "#535ac8",
    700: "#3e4396",
    800: "#2a2d64",
    900: "#151632"
  },
};

const typography = {
  fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  fontSize: 12,
  h1: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    fontSize: 40,
  },
  h2: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    fontSize: 32,
  },
  h3: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    fontSize: 24,
  },
  h4: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    fontSize: 20,
  },
  h5: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    fontSize: 16,
  },
  h6: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    fontSize: 14,
  },
};

const lightColors = {
  background: {
    default: '#F5F5F5',  // Soft Light Gray Background
    paper: '#FFFFFF',     // Clean White for Paper Components
  },
  text: {
    primary: '#333333',   // Dark Gray for Text
    secondary: '#555555', // Slightly lighter gray for secondary text
  },
  primary: {
    main: '#4A90E2',      // Professional Blue for Primary Actions
  },
  secondary: {
    main: '#B0B0B0',      // Neutral Gray for Secondary Elements
  },
};

const darkColors = {
  background: {
    default: '#1E1E1E',   // Dark Gray Background
    paper: '#2C2C2C',     // Darker Gray for Paper Components
  },
  text: {
    primary: '#E0E0E0',   // Light Gray for Text
    secondary: '#B3B3B3', // Slightly lighter gray for secondary text
  },
  primary: {
    main: '#4A90E2',      // Consistent Blue for Primary Actions
  },
  secondary: {
    main: '#484848',      // Dark Gray for Secondary Elements
  },
};

interface ColorModeContextProps {
  toggleColorMode: () => void;
  mode: 'light' | 'dark';
}

export const ColorModeContext = createContext<ColorModeContextProps | undefined>(undefined);

export const useColorMode = () => {
  const context = useContext(ColorModeContext);

  if (!context) {
      throw new Error('useColorMode must be used within a ColorModeProvider');
  }

  return context;
};

export const ColorModeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');

  const colorMode = useMemo<ColorModeContextProps>(
    () => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
        mode,
    }),
    [mode]
);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light' ? lightColors : darkColors),
        },
        typography,
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode} >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
};
