import React from 'react';
import { Box, Typography } from '@mui/material';

const TypingIndicator: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '20px',
        backgroundColor: '#e0e0e0',
        borderRadius: '15px',
        padding: '5px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <Box
          sx={{
            width: '6px',
            height: '6px',
            backgroundColor: '#888',
            borderRadius: '50%',
            animation: 'typing 1.2s infinite',
            '@keyframes typing': {
              '0%, 100%': { opacity: 0.2 },
              '50%': { opacity: 1 },
            },
          }}
        />
        <Box
          sx={{
            width: '6px',
            height: '6px',
            backgroundColor: '#888',
            borderRadius: '50%',
            animation: 'typing 1.2s infinite 0.2s',
            '@keyframes typing': {
              '0%, 100%': { opacity: 0.2 },
              '50%': { opacity: 1 },
            },
          }}
        />
        <Box
          sx={{
            width: '6px',
            height: '6px',
            backgroundColor: '#888',
            borderRadius: '50%',
            animation: 'typing 1.2s infinite 0.4s',
            '@keyframes typing': {
              '0%, 100%': { opacity: 0.2 },
              '50%': { opacity: 1 },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TypingIndicator;
