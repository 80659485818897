// src/components/ProcessingDialog.tsx

import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress,
    Typography,
    Box
} from '@mui/material';
import { useProcessingDialog } from '../context/ProcessingDialogContext';

const ProcessingDialog: React.FC = () => {
    const { open, title, message, setDialog } = useProcessingDialog();

    const handleClose = () => {
        setDialog(false, '', ''); // Close the dialog and reset the state
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <DialogTitle>
                    <Box>
                        <Typography variant='h4'>
                            {title}
                        </Typography>
                    </Box>

                </DialogTitle>
                <DialogContent className="flex flex-col items-center bg-black">
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CircularProgress className="mb-4" />
                    </Box>
                    <Typography variant='h5'>
                        {message}
                    </Typography>
                </DialogContent>
            </Box>
        </Dialog>
    );
};

export default ProcessingDialog;
