import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Collapse,
    Paper,
    IconButton,
    Typography,
    Box,
    useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useDocumentList } from '../../hook/useDocumentList';
import { useQuery } from '@tanstack/react-query';
import { groupBy } from '../../utils/common';
import { useColorMode } from '../../context/ColorModeContext';

const ROW_PER_PAGE = 10;
const childrenPerPage = 5;

const ChatLogsTable: React.FC = () => {
    const theme = useTheme()
    const { mode } = useColorMode();
    const isDarkMode = mode === "dark";
    const [rows, setRows] = useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE);
    const [childrenPage, setChildrenPage] = useState<{ [key: number]: number }>({});

    const { fetchDocumentList } = useDocumentList()

    const { data, isLoading, isError, error } = useQuery({
        queryKey: ['document-list'],
        queryFn: () => fetchDocumentList({
            page_size: 10000
        })
    });

    useEffect(() => {
        const groupByLocation = data ? groupBy(data, 'location', 'source') : []
        setRows(groupByLocation)
    }, [data])

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleToggle = (location: string) => {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.location === location
                    ? { ...row, expanded: !row.expanded }
                    : row
            )
        );
    };

    const handleChildrenPageChange = (parentId: string, newPage: number) => {
        setChildrenPage((prev) => ({ ...prev, [parentId]: newPage }));
    };

    const handleOpenSoucre = ({ url }: { url: string }) => {
        if (url) {
            window.open(url, '_blank')
        }
    }

    const handleTitle = (title: string) => {
        return title
        .split('_') // Split the string by underscores
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words with spaces
    }

    return (
        <Box m="0 20px 20px 20px">
            <Box>
                <Typography variant='h3' mb={"20px"}>
                    Document list
                </Typography>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <React.Fragment key={row.location}>
                                        <TableRow
                                            onClick={() => handleToggle(row.location)}
                                            style={{ cursor: 'pointer', backgroundColor: theme.palette.background.paper }}
                                        >
                                            <TableCell>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <IconButton size="small">
                                                        {row.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </IconButton>
                                                    <Typography variant="h5">{handleTitle(row.location)}</Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={1} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                                <Collapse in={row.expanded} timeout="auto" unmountOnExit>
                                                    <Table>
                                                        <TableBody>
                                                            {row.items
                                                                ?.slice(
                                                                    (childrenPage[row.location] || 0) * childrenPerPage,
                                                                    ((childrenPage[row.location] || 0) + 1) * childrenPerPage
                                                                )
                                                                .map((child: any) => (
                                                                    <TableRow key={child.source} sx={{
                                                                        cursor: 'pointer', '&:hover': {
                                                                            backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)'
                                                                        }
                                                                    }} onClick={() => handleOpenSoucre({ url: child.source })}>
                                                                        <TableCell>
                                                                            <Typography variant="body1" color="textSecondary">
                                                                                {handleTitle(child.title)}
                                                                            </Typography>
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                {child.source}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        {/* <TableCell>
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                {child.details}
                                                                            </Typography>
                                                                        </TableCell> */}
                                                                    </TableRow>
                                                                ))}
                                                        </TableBody>
                                                    </Table>
                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 1 }}>
                                                        <TablePagination
                                                            rowsPerPageOptions={[childrenPerPage]}
                                                            component="div"
                                                            count={row.items?.length || 0}
                                                            rowsPerPage={childrenPerPage}
                                                            page={childrenPage[row.location] || 0}
                                                            onPageChange={(event, newPage) => handleChildrenPageChange(row.location, newPage)}
                                                        />
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </Box>
    );
};

export default ChatLogsTable;
