// src/hooks/useConversations.ts
import { useAuth } from '../context/AuthContext';
import { useApiRequest } from './useApiRequest';


interface getUserPrams {
    token?: string;
}

interface getClientIdPrams {
    token?: string;
}

interface getUpdateClientIdPrams {
    clientId?: string;
}

export const useUser = () => {
    const { apiRequest } = useApiRequest();
    const { token } = useAuth()

    const getUserInfo = async ({
        token,
    }: getUserPrams): Promise<any> => {
        return apiRequest({
            token,
            endpoint: '/api/v1/users/user-info',
            transformResponse: (response) =>
                response.json().then((data: any) => {
                    return data.data;
                }),
        });
    };

    const getClientId = async ({
        token,
    }: getClientIdPrams): Promise<any> => {
        return apiRequest({
            token,
            endpoint: '/api/v1/users/client-id',
            transformResponse: (response) =>
                response.json().then((data: any) => {
                    return data.data?.client_ids;
                }),
        });
    };

    const updateClientId = async ({
        clientId,
    }: getUpdateClientIdPrams): Promise<any> => {
        return apiRequest({
            token,
            method: 'POST',
            body: {client_id: clientId},
            endpoint: '/api/v1/users/client-id/change',
            transformResponse: (response) =>
                response.json().then((data: any) => {
                    return data;
                }),
        });
    };

    return { getUserInfo, getClientId, updateClientId };
};
