import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Avatar, Typography } from '@mui/material';
import LastChatMessageCell from './LastChatMessageCell';


interface ChatLog {
    ticket_id: string,
    lastChatMessage: string,
    conversation_id: string,
    timestamp: string,
    user_role: string,
    category: string,
    associatedTicket: string,
    status: string,
    created_at: string,
    updated_at: string,
    summary: string
}

interface ChatLogDataGridProps {
    dataChatLogs: ChatLog[];
    currentPage: number;  // Track current page to preserve state
    selectedTab?: number;
}

const ChatLogDataGrid: React.FC<ChatLogDataGridProps> = ({
    dataChatLogs,
    currentPage,
    selectedTab
}) => {

    const columns: GridColDef[] = [
        { field: 'ticket_id', headerName: 'Ticket ID', width: 100 },
        {
            field: 'summary', headerName: 'AI Summary of Issue', width: 250,
            renderCell: (params) => {
                return (
                    <Box sx={{ display: "flex", p: 1, flexDirection: "column", justifyContent: 'center', height: '100%' }}>
                        <Typography variant="body1" sx={{
                            wordWrap: 'break-word',  // Break words if they are too long
                            whiteSpace: 'normal',    // Allow the text to break onto a new line
                            overflow: 'hidden',      // Prevent overflow beyond the container
                            textOverflow: 'ellipsis' // Add ellipsis if the text is too long
                        }}>
                            {params.row.summary}
                        </Typography>
                    </Box>
                )
            },
        },
        {
            field: 'assignee', headerName: 'Assignee', flex: 1,
            renderCell: (params) => {
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", height: '100%' }}>
                        {params.row.assignee?.avatarUrls && <Avatar
                            alt={params.row.assignee?.displayName}
                            src={params.row.assignee?.avatarUrls["48x48"]}
                            sx={{ width: 32, height: 32, alignSelf: 'center' }} // Adjust size if needed
                        />}
                        <Typography variant="body1" sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap', 
                            textAlign: 'center',
                        }}>
                            {params.row.assignee?.displayName}
                        </Typography>
                    </Box>
                )
            },

        },
        // { field: 'assignee', headerName: 'Assignee', flex: 1 },
        {
            field: 'lastChatMessage', headerName: 'Ticket Details', flex: 1,
            renderCell: (params) => (
                <LastChatMessageCell message={params.row.last_message} ticketId={params.row.ticket_id} ticketData={params.row} conversationId={params.row.conversation_id} ticketUrl={params.row.ticket_url} currentPage={currentPage} selectedTab={selectedTab} />// Pass currentPage to the cell/>
            )
        },
        { field: 'user_role', headerName: 'Ticket Type', flex: 1, },
        { field: 'category', headerName: 'Category', width: 100 },
        { field: 'status', headerName: 'Status', flex: 1 },
        { field: 'created_at', headerName: 'Created Time', width: 120},
        { field: 'updated_at', headerName: 'Updated Time', width: 120 },
    ];

    return (
        <DataGrid
            pageSizeOptions={[5]}
            rowSelection={false}
            pagination
            getRowId={(row) => row.ticket_id}
            rows={dataChatLogs}
            columns={columns}
            rowHeight={100}
            disableColumnResize
        />
    )
}

export default ChatLogDataGrid;
