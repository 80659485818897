import React from 'react';
import { convertToTitleCase } from '../utils/common';

interface PriorityCellProps {
  level: string,
}

const priorityColors: { [key: string]: string } = {
  High: 'red',
  Medium: 'orange',
  Low: 'green',
};

const PriorityCell: React.FC<PriorityCellProps> = ({ level }) => {
  const color = priorityColors[level] || 'gray';
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: color,
          marginRight: 8
        }}
      />
      {convertToTitleCase(level)}
    </div>
  );
};

export default PriorityCell;
