// src/pages/CSAgentChatLogs.tsx
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ChatLogDataGrid from '../../components/ChatLogsDataGrid';
import Pagination from '../../components/Pagination';

const CSAgentChatLogs: React.FC<{
    currentPage: number;
    totalPages: number;
    tickets: any[];
    onPageChange: (page: number) => void;
}> = ({ currentPage, totalPages, tickets, onPageChange }) => {
    const theme = useTheme();

    return (
        <Box>
            <Typography variant='h3' mb={"16px"}>
                Listing ticket logs
            </Typography>
            <Box
                m="20px 0"
                sx={{
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: theme.palette.primary.main,// Different styling for CS Agent
                    },
                }}
            >
                <ChatLogDataGrid dataChatLogs={tickets} currentPage={currentPage} />
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={onPageChange} />
            </Box>
        </Box>
    );
}

export default CSAgentChatLogs;
