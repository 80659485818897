// src/pages/Home.tsx
import React, { useState } from 'react';
import { Box, Typography, useTheme, Grid } from '@mui/material';
import Header from '../../components/Header';
import ChartBox from '../../components/ChartBox';
import TicketsDataGrid from '../../components/LastTicketsDataGrid';
import { useQuery } from '@tanstack/react-query';
import Pagination from '../../components/Pagination';
import { useTickets } from '../../hook/useTickets';

const CSAgentHome: React.FC = () => {
    const theme = useTheme()
    const { fetchTickets } = useTickets()

    const [currentPage, setCurrentPage] = useState<number>(1);

    const pageSize = 10;
    // Fetch tickets based on the selected tab and page
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ['active-tickets', currentPage],
        queryFn: () => fetchTickets({
            is_assigned: false,
            page: 1,
            page_size: pageSize
        }),
    });

    const totalPages = data?.totalPages || 0;  // Adjust based on API response
    const tickets = data?.tickets || [];  // Your data model

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <Box>
            {/* LASTKETS */}
            <Box
                display={"flex"}
                flexDirection={"column"}
                height={"84vh"}
                sx={{
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: theme.palette.primary.main,
                        borderBottom: "none",
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
                        color: theme.palette.background.paper, // Color for the checkbox in the header
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        color: theme.palette.text.primary,
                        ...theme.typography.h5
                        // Set the desired color for the text in the header row
                    },
                }}
            >
                <Typography variant='h3' mb={"20px"} color={theme.palette.text.primary}>
                    Active Tickets
                </Typography>
                <TicketsDataGrid ticketsData={tickets} currentPage={currentPage} />
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </Box>
        </Box>
    );
};

export default CSAgentHome;
