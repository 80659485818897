import React from 'react';
import { Button } from '@mui/material';

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageChange }) => {
    const getPages = () => {
        const pages = [];
        const maxVisiblePages = 5;  // How many page numbers you want to show

        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        if (startPage > 1) {
            pages.push(1);
            if (startPage > 2) {
                pages.push('...');
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push('...');
            }
            pages.push(totalPages);
        }

        return pages;
    };

    const handleClick = (page: number | string) => {
        if (typeof page === 'number') {
            onPageChange(page);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
            {getPages().map((page, index) => (
                <React.Fragment key={index}>
                    {page === '...' ? (
                        <span style={{ margin: '0 5px' }}>...</span>
                    ) : (
                        <Button
                            variant={page === currentPage ? 'contained' : 'outlined'}
                            //   color={page === currentPage ? 'primary' : 'default'}
                            sx={{
                                color: page === currentPage ? 'primary' : 'default'
                            }}
                            onClick={() => handleClick(page as number)}
                            style={{ margin: '0 5px' }}
                        >
                            {page}
                        </Button>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default Pagination;
