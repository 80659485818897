// src/pages/Home.tsx
import React from 'react';
import { Box } from '@mui/material';
import AdminHome from './AdminHome';
import CSAgentHome from './CSAgentHome';
import { useAuth } from '../../context/AuthContext';

const Home: React.FC = () => {
  const {user} = useAuth();

  return (
    <Box m="0 20px 20px 20px">
      {user.role === 'admin' ? (
        <AdminHome />
      ) : (
        <CSAgentHome />
      )}
    </Box>
  );
};

export default Home;
