import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../utils/http';
import { useConversations } from './useConversations';

const useSaveConversationName = () => {
    const { updateConversation } = useConversations()
    return useMutation({
        mutationFn: updateConversation,
        onMutate: (updatedConversation) => {
            queryClient.setQueryData(['conversations'], (oldData: any) => {
                if (!oldData) return oldData;

                const updatedPages = oldData.pages.map((page: any) => (page.map((conv: any) =>
                            conv.id === updatedConversation.conversation_id
                                ? { ...conv, name: updatedConversation.name }
                                : conv
                        )
                ))

                return {
                    ...oldData,
                    pages: updatedPages,
                };
            })
        }
    })

}

export default useSaveConversationName;