import { useAuth } from "../context/AuthContext";
import { useFetchToken } from "./useFetchToken";
import { useProcessingDialog } from '../context/ProcessingDialogContext';

// src/hooks/useConversations.ts
export const useAuthentication = () => {
  const { fetchToken } = useFetchToken();
  const { setToken, setUser, clearAuth } = useAuth();

  const login = async (email: string, password: string): Promise<any> => {
    // Check if the email and password are valid
    if ((email === 'admin' || email === 'cs_agent') && password === '123456') {
      try {
        const result = await fetchToken('66e7d0eaeb89aa38b75d9ee2');

        if (result.error_code === 0) {
          // Set role based on the email
          result.data.role = email === 'admin' ? 'admin' : 'cs_agent';

          // Set token and user in the context
          setToken(result.data.token);
          setUser(result.data);

          return result;
        } else {
          console.error('Login failed:', result.message);
          return { error: true, message: result.message };
        }
      } catch (error) {
        console.error('Error fetching token:', error);
        return { error: true, message: 'An error occurred during login' };
      }
    } else {
      console.error('Login failed: Invalid credentials');
      return { error: true, message: 'Invalid email or password' };
    }
  };

  // src/utils/logout.ts

  const logout = async (setDialog: (open: boolean, title: string, message: string) => void) => {
    const main_url = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem("authToken"); // Adjust as necessary to retrieve your token
    setDialog(true, 'Processing Logout', 'Please wait while we log you out...');

    try {
      const headers: HeadersInit = {
        'Content-Type': 'application/json',

      };

      // Conditionally add token to headers if it's not null
      if (token) {
        headers['Authorization'] = token;
      }

      const response = await fetch(`${main_url}/api/v1/users/auth/logout`, {
        method: 'GET',
        headers: headers,
      });

      if (response.status === 200) {
        localStorage.removeItem("user");
        clearAuth(); // Clears token and user from context
        console.log("Logged out successfully");
      } else {
        console.error("Logout failed with status:", response.status);
      }
    } catch (e) {
      console.log("Error during logout:", e);
    } finally {
      setDialog(false, '', ''); // Close the dialog regardless of success or failure
    }
  };

  return { login, logout };
};
