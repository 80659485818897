import React from 'react';
import { convertToTitleCase } from '../utils/common';

interface StatusCellProps {
  value: string,
}

const statusColors: { [key: string]: string } = {
  IN_PROGRESS: 'blue',
  COMPLETED: 'green',
  ON_HOLD: 'orange',
};

const StatusCell: React.FC<StatusCellProps> = ({ value }) => {
  const color = statusColors[value] || 'gray';
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: color,
          marginRight: 8
        }}
      />
       {convertToTitleCase(value)}
    </div>
  );
};

export default StatusCell;
