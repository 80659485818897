import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton, Button, CircularProgress, Snackbar, Alert, useTheme } from '@mui/material';
import Close from '@mui/icons-material/Close';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import TypingIndicator from './TypingIndicator';
import { queryClient } from '../utils/http';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import InputBar from './InputBar';
import MessageBubble from './MessageBubble';
import { useConversations } from '../hook/useConversations';

interface Message {
    from_user: boolean;
    id: string;
    message: string;
    sender_id: string;
}

interface ConversationChatProps {
    conversationId: string | null;
    isCreatedTicket?: boolean;
    handleCloseChat?: () => void;
    selectNewConversationCreated: () => void;
    hideInputBar?: boolean;
}

const ConversationChat: React.FC<ConversationChatProps> = ({ conversationId, selectNewConversationCreated, isCreatedTicket, handleCloseChat, hideInputBar }) => {
    const theme = useTheme();
    const [messages, setMessages] = useState<Message[]>([]);
    const [isInputDisabled, setIsInputDisabled] = useState(false);
    const [errorSystem, setErrorSystem] = useState<string | null>(null);
    const [notAllowContinueConversation, setNotAllowContinueConversation] = useState<boolean | undefined>(false);
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const conversationIdRef = useRef<string | null>();

    const { createConversation, fetchConversationMessages, sendConversationMessages } = useConversations()

    const fetchMessages = async ({ pageParam }: { pageParam?: string }) => {
        const response = await fetchConversationMessages({
            conversation_id: conversationId,
            page_size: 20,
            last_message_id: pageParam,
            signal: new AbortController().signal
        });
        return response;
    };

    const {
        data,
        error,
        fetchPreviousPage,
        isFetching,
        isFetchingPreviousPage,
    } = useInfiniteQuery({
        queryKey: ['conversation-messages', conversationId],
        queryFn: fetchMessages,
        initialPageParam: undefined,
        getNextPageParam: (lastPage, pages) => {
            return undefined;
        },
        getPreviousPageParam: (firstPage, pages) => {
            const lastMessage = firstPage.data[0];
            return lastMessage ? lastMessage.id : undefined;
        },
    })

    const { mutate } = useMutation({
        mutationFn: async ({ conversation_id, message }: { conversation_id: null | string; message: string }) => {
            const { responseStream, status } = await sendConversationMessages({ conversation_id, message });
            
            if (status === 202) {
                await queryClient.invalidateQueries({ queryKey: ['conversations'] })
            }

            if (status === 500) {
                setErrorSystem('Something error!')
                setIsTyping(false);
                setIsInputDisabled(false);
                setTimeout(() => {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }, 100)
                return
            }
            if (status === 201) {
                setNotAllowContinueConversation(true)
                await queryClient.invalidateQueries({ queryKey: ['conversations'] })
            }

            if (conversationId === null) {
                selectNewConversationCreated()
            }

            const reader = responseStream.getReader();
            const decoder = new TextDecoder("utf-8");
            let done = false;
            let responseText = "";

            while (!done) {
                const { value, done: readerDone } = await reader.read();
                done = readerDone;

                if (value) {
                    responseText += decoder.decode(value, { stream: true });
                    setMessages((prevMessages) => {

                        const lastMessage = prevMessages[0];
                        if (lastMessage?.sender_id === '-1') {
                            prevMessages.shift()
                            return [
                                {
                                    ...lastMessage,
                                    message: responseText,
                                    sender_id: '-1',
                                    from_user: false
                                },
                                ...prevMessages,
                            ];
                        } else {
                            return [
                                {
                                    id: uuidv4(),
                                    message: responseText,
                                    sender_id: '-1',
                                    from_user: false
                                },
                                ...prevMessages,
                            ];
                        }
                    });
                    scrollToBottom(100)

                }
            }

            setIsTyping(false);
            setIsInputDisabled(false);
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100)
        },
        onError: (error) => {
            console.error('Error during message sending:', error);
            setIsTyping(false);
        },
        onMutate: () => {
            setIsTyping(true);
        },
    });

    const createNewConversations = useMutation({
        mutationFn: async ({ name }: { name?: string }) => {
            const response = await createConversation({ name })
            return response
        }
    })

    const handleSendMessage = async (input: string) => {
        setErrorSystem(null)
        let currentconversationId = conversationId
        if (currentconversationId === null) {
            const response: any = await createNewConversations.mutateAsync({name: ''});
            if (response.id) {
                currentconversationId = response.id
                await queryClient.invalidateQueries({ queryKey: ['conversations'] })
            }
        }

        mutate({ conversation_id: currentconversationId, message: input })
        if (input.trim()) {
            const userMessage: Message = {
                id: uuidv4(),
                message: input,
                sender_id: '1',
                from_user: true
            };

            setMessages((prevMessages) => [userMessage, ...prevMessages]);
            setIsInputDisabled(true);
            setIsTyping(true);
            const container = containerRef.current;
            if (container) {
                setTimeout(() => {
                    container.scrollTop = container.scrollHeight;
                }, 100)
            }


        }
    };

    const scrollToBottom = (timeOut: number) => {
        const container = containerRef.current;
        if (container) {
            setTimeout(() => {
                container.scrollTop = container.scrollHeight;
            }, timeOut)
        }
    }

    useEffect(() => {
        setNotAllowContinueConversation(isCreatedTicket)

        scrollToBottom(500)
        return () => {
            queryClient.setQueryData(['conversation-messages', conversationId], {
                pages: [],
                pageParams: [],
            });
        }
    }, [conversationId])

    useEffect(() => {
        if (data) {
            const container = containerRef.current;
            if (container) {
                const fetchedMessages = [...data.pages.flatMap(page => page.data)];
                if (conversationIdRef.current === conversationId) {
                    setMessages((prevMessages) => {
                        const updatedMessages = [...prevMessages, ...fetchedMessages.reverse(),];
                        return Array.from(new Set(updatedMessages.map(msg => msg.id))).map(id =>
                            updatedMessages.find(msg => msg.id === id)
                        );
                    });
                } else {
                    setMessages(fetchedMessages.reverse())

                }
            }
            conversationIdRef.current = conversationId
        }
    }, [data]);

    const handleScrolll = (event: React.UIEvent<HTMLDivElement>) => {
        const top = event.currentTarget.scrollHeight <= event.currentTarget.clientHeight - event.currentTarget.scrollTop + 50;

        if (top && !isFetchingPreviousPage) {
            fetchPreviousPage();
        }
    };


    let content;

    if (isFetching) {
        content = <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={40} color={'inherit'} />
        </Box>
    }

    if (error) {
        content = <Typography variant='h4'>{error?.message}</Typography>
    }

    if (messages) {
        content = <Box ref={containerRef} onScroll={handleScrolll} sx={{ position: 'relative', display: "flex", flexDirection: 'column-reverse', flex: 1, p: 2, overflowY: 'auto', pb: 6 }}>
            {isTyping && <TypingIndicator />}

            {errorSystem && (
                <Box sx={{ width: '30%', alignSelf: 'center' }}>
                    <Alert severity="error" onClose={() => setErrorSystem(null)}>
                        {errorSystem}
                    </Alert>
                </Box>
            )}
            {messages.map((message: Message) => (
                <MessageBubble
                    key={message.id}
                    message={message.message}
                    isSentByUser={message.from_user}
                />
            ))}

        </Box>
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box sx={{
                color: theme.palette.text.primary,
                p: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: "row",
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ display: "flex", width: 30, height: 30, borderRadius: 30, background: "#d6262e", alignItems: "center", justifyContent: "center" }}>
                        <SmartToyOutlinedIcon fontSize='medium' sx={{ color: "#fff" }} />
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <Typography sx={{ fontWeight: 600 }} variant="h5">Vera</Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Box sx={{ mr: 1, height: 8, width: 8, borderRadius: 8, background: '#4CAF50' }} />
                            <Typography variant="h6">Online</Typography>

                        </Box>
                    </Box>
                </Box>

                {handleCloseChat ? <Box>
                    <IconButton sx={{ alignSelf: 'flex-end' }} aria-label="clear-input" onClick={handleCloseChat}>
                        <Close sx={{ fontSize: 14, color: '#000' }} />
                    </IconButton>

                </Box> : null}
            </Box>
            {content}
            {hideInputBar ? null : <Box sx={{ mx: 1, mb: 1 }}>
                <InputBar inputRef={inputRef} notAllowContinueConversation={notAllowContinueConversation} conversationId={conversationId} onSendMessage={handleSendMessage} disabled={isInputDisabled || notAllowContinueConversation} />
            </Box>}
        </Box>
    );
};

export default ConversationChat;
