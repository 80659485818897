import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useColorMode, colors } from '../context/ColorModeContext';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css'

interface MessageBubbleProps {
    message: string;
    isSentByUser?: boolean; // Optional prop to differentiate user and received messages
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ message, isSentByUser = true }) => {
    const theme = useTheme()
    const { mode } = useColorMode();
    const isDarkMode = mode === "dark";
    return (
        <Box
            sx={{
                padding: '8px 12px',
                borderRadius: '16px',
                maxWidth: '80%',
                margin: '4px 0',
                alignSelf: isSentByUser ? 'flex-end' : 'flex-start',
                backgroundColor: isSentByUser ? isDarkMode ? colors.grey[600] : '#f1f1f1' : 'none',
                color: theme.palette.text.primary,
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
            }}
        >
            {isSentByUser
                ? <Typography variant="body1" component="div">
                    {message}
                </Typography>
                : <ReactMarkdown
                    remarkPlugins={[gfm, remarkMath]} rehypePlugins={[rehypeKatex]}
                    children={message}
                />
            }
        </Box>
    );
};

export default MessageBubble;
