// src/hooks/useConversations.ts
import { useApiRequest } from './useApiRequest';

export const useFetchToken = () => {
    const { apiRequest } = useApiRequest();


    const fetchToken = async (userId: string | null): Promise<any> => {

        return apiRequest({
            method: 'POST',
            endpoint: '/api/v1/users/external/auth',
            body: { user_id: userId },
        });
    };

    return { fetchToken };
};
