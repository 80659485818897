import React, { useState, useRef } from 'react';
import { List, ListItemButton, ListItemText, ListItemIcon, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConversationItem from './ConversationItem';

interface Conversation {
    id: string;
    name: string;
    ticket_flag: boolean;
    updated_at: string;
}

interface ConversationListProps {
    conversations: Conversation[];
    selectedConversationId: string | null;
    onSelectConversation: (id: string, isCreatedTicket: boolean) => void;
    handleCreateConversation: () => void;
}

const ConversationList: React.FC<ConversationListProps> = ({ conversations, selectedConversationId, handleCreateConversation, onSelectConversation }) => {
    return (
        <List sx={{ alignItems: "center" }}>
            {conversations.map((conversation) => (
                <ConversationItem
                key={conversation.id}
                conversation={conversation}
                isSelected={selectedConversationId === conversation.id}
                onSelect={onSelectConversation}
                handleCreateConversation={handleCreateConversation}
            />
            ))}
        </List>
    );
};

export default ConversationList;
