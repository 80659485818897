import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Box, Avatar, Typography } from '@mui/material';
import PriorityCell from './PriorityCell';
import StatusCell from './StatusCell';
import useSelectTicket from '../hook/useSelectTicket';
import { useAuth } from '../context/AuthContext';

interface TicketsProps {
    ticket_id: string,
    lastChatMessage: string,
    timestamp: string,
    category: string,
    priority: string,
    status: string,
    created_at: string,
}


interface TicketsDataGridProps {
    ticketsData: TicketsProps[];
    currentPage: number;
    selectTicket?: ({ ticket_id }: { ticket_id: string }) => void
}

const TicketsDataGrid: React.FC<TicketsDataGridProps> = ({ ticketsData, currentPage }) => {
    const {user} = useAuth()

    const { mutate: selectTicket } = useSelectTicket(currentPage)

    const columns: GridColDef[] = [
        { field: 'ticket_id', headerName: 'Ticket ID', width: 100 },
        {
            field: 'summary', headerName: 'AI Summary of Issue', width: 250,
            renderCell: (params) => {
                return (
                    <Box sx={{ display: "flex", p: 1, flexDirection: "column", justifyContent: 'center', height: '100%' }}>
                        <Typography variant="body1" sx={{
                            wordWrap: 'break-word',  // Break words if they are too long
                            whiteSpace: 'normal',    // Allow the text to break onto a new line
                            overflow: 'hidden',      // Prevent overflow beyond the container
                            textOverflow: 'ellipsis' // Add ellipsis if the text is too long
                        }}>
                            {params.row.summary}
                        </Typography>
                    </Box>
                )
            },
        },
        { field: 'category', headerName: 'Category', flex: 1 },
        {
            field: 'priority',
            headerName: 'Priority',
            flex: 1,
            renderCell: (params) => <PriorityCell level={params.value} />
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => <StatusCell value={params.value} />
        },
        { field: 'created_at', headerName: 'Time created', flex: 1 },
        {
            field: 'assignee', headerName: 'Assignee', flex: 1,
            renderCell: (params) =>
                (user.role === 'cs_agent') ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => selectTicket({ ticket_id: params.row.ticket_id })}
                        style={{ marginLeft: 10, fontSize: 10 }}
                    >
                        Select ticket
                    </Button>
                ) : <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", height: '100%'}}>
                    {params.row.assignee?.avatarUrls && <Avatar
                        alt={params.row.assignee?.displayName}
                        src={params.row.assignee?.avatarUrls["48x48"]}
                        sx={{ width: 32, height: 32 }} // Adjust size if needed
                    />}
                    <Typography variant="body1" sx={{ marginLeft: 2 }}>
                        {params.row.assignee?.displayName}
                    </Typography>
                </Box>

        },
    ]
    return (
        <DataGrid
            pageSizeOptions={[10]}
            rowSelection={false}
            getRowId={(row) => row.ticket_id}
            rows={ticketsData}
            rowHeight={100}
            columns={columns} />
    )
}

export default TicketsDataGrid;
